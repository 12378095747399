import { createSlice } from "@reduxjs/toolkit";

const mainSolutionCardClickSlice = createSlice({
    name: "mainSolutionCardClickSlice",
    initialState: { value: "" },
    reducers: {
        MainSolutionCardClick: (state, actions) => {
            state.value = actions.payload;
        },
    },
});

export default mainSolutionCardClickSlice;
export const { MainSolutionCardClick } = mainSolutionCardClickSlice.actions;
