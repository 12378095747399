import { createSlice } from "@reduxjs/toolkit";

const count = createSlice({
    name: "count",
    initialState: { value: false },
    reducers: {
        Count: (state) => {
            state.value = !state.value;
        },
    },
});

export default count;
export const { Count } = count.actions;
