import "./News.css";
import { useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import API from "utils/api";

import prevColor from "assets/images/icons/main_news_prev_color_button.png";
import nextColor from "assets/images/icons/main_news_next_color_button.png";
import card1Img from "assets/images/main/card1.png";
import card2Img from "assets/images/main/card2.png";
import card3Img from "assets/images/main/card3.png";
import card4Img from "assets/images/main/card4.png";
import card5Img from "assets/images/main/card5.png";

function News() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [cardAreaWidth, setCardAreaWidth] = useState(0);
    const [cardWidth, setCardWidth] = useState(0);
    const [cardBtn, setCardBtn] = useState(0);

    const cardWidthHandle = () => {
        if (window.innerWidth > 800) {
            setCardAreaWidth(2185);
            setCardWidth(437);
        } else if (window.innerWidth > 500) {
            setCardAreaWidth(1690);
            setCardWidth(338);
        } else {
            setCardAreaWidth(1390);
            setCardWidth(278);
        }
    };

    useLayoutEffect(() => {
        window.addEventListener("resize", cardWidthHandle);
        window.onload = cardWidthHandle();
    }, []);

    const sliderMove = (num) => {
        if (window.innerWidth <= 1161) {
            if (num === +1 && cardBtn < 4) {
                setCardBtn(cardBtn + num);
            } else if (num === -1 && cardBtn > 0) {
                setCardBtn(cardBtn + num);
            }
        } else if (window.innerWidth <= 1674) {
            if (num === +1 && cardBtn < 3) {
                setCardBtn(cardBtn + num);
            } else if (num === -1 && cardBtn > 0) {
                setCardBtn(cardBtn + num);
            }
        } else {
            if (num === +1 && cardBtn < 2) {
                setCardBtn(cardBtn + num);
            } else if (num === -1 && cardBtn > 0) {
                setCardBtn(cardBtn + num);
            }
        }
    };

    // news list get useEffect
    const newslistPageNum = useSelector((state) => {
        return state.newslistPageNum.value;
    });
    const [newsList, setNewsList] = useState([]);
    useEffect(() => {
        API.news_get(JSON.stringify([newslistPageNum, 3]))
            .then((res) => {
                // console.log(res.data);
                if (res.data["result"] !== "null") {
                    setNewsList(res.data["result"]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [newslistPageNum]);

    // console.log(newsList);

    const toString = (time) => {
        var getTime = new Date(time);
        const year = getTime.getFullYear();
        const month = getTime.getMonth() + 1;
        const date = getTime.getDate();
        return `${year}-${month >= 10 ? month : "0" + month}-${
            date >= 10 ? date : "0" + date
        }`;
    };

    return (
        <div className="MainNewsWrap">
            <div className="MainNewsTitle">
                <div className="MainNewsTitleText1">
                    {t(`main.news.reportTitleText`)}
                </div>
                <div className="MainNewsTitleText2">
                    {t(`main.news.titleText2`)}
                </div>
            </div>
            <div className="MainNewsSlide">
                <div className="MainNewsCards">
                    <div className="MainNewsBtn prev">
                        <img
                            src={prevColor}
                            alt="Previous Button"
                            onClick={() => sliderMove(-1)}
                        />
                    </div>
                    <div className="MainNewsCardWrap">
                        <div
                            className="MainNewsCardArea"
                            style={{
                                width: cardAreaWidth + "px",
                                transform: `translateX(${
                                    -cardWidth * cardBtn
                                }px)`,
                                transition: "0.5s ease",
                            }}
                        >
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.youtube.com/watch?v=beW5UBKEK90"
                            >
                                <div className="MainNewsCard">
                                    <div className="MainNewsCardImage">
                                        <img src={card1Img} alt="card1 img" />
                                    </div>
                                    <div className="MainNewsCardTitle">
                                        <p>{t(`main.news.card1Title`)}</p>
                                    </div>
                                    <div className="MainNewsCardLine"></div>
                                    <div className="MainNewsCardText">
                                        <p>{t(`main.news.card1Text1`)}</p>
                                        <p>{t(`main.news.card1Text2`)}</p>
                                    </div>
                                </div>
                            </a>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.youtube.com/watch?v=oM-uG8ArHR0"
                            >
                                <div className="MainNewsCard">
                                    <div className="MainNewsCardImage">
                                        <img src={card2Img} alt="card2 img" />
                                    </div>
                                    <div className="MainNewsCardTitle">
                                        <p>{t(`main.news.cardTitle`)}</p>
                                        <p>{t(`main.news.card2Title`)}</p>
                                    </div>
                                    <div className="MainNewsCardLine"></div>
                                    <div className="MainNewsCardText">
                                        <p>{t(`main.news.card2Text`)}</p>
                                    </div>
                                </div>
                            </a>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.youtube.com/watch?v=9NtxY5UHqFU"
                            >
                                <div className="MainNewsCard">
                                    <div className="MainNewsCardImage">
                                        <img src={card3Img} alt="card3 img" />
                                    </div>
                                    <div className="MainNewsCardTitle">
                                        <p>{t(`main.news.cardTitle`)}</p>
                                        <p>{t(`main.news.card3Title`)}</p>
                                    </div>
                                    <div className="MainNewsCardLine"></div>
                                    <div className="MainNewsCardText">
                                        <p>{t(`main.news.card3Text`)}</p>
                                    </div>
                                </div>
                            </a>
                            
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://youtu.be/PEzS7KjiCNo"
                            >
                                <div className="MainNewsCard">
                                    <div className="MainNewsCardImage">
                                        <img src={card4Img} alt="card4 img" />
                                    </div>
                                    <div className="MainNewsCardTitle">
                                        <p>{t(`main.news.cardTitle`)}</p>
                                        <p>{t(`main.news.card4Title`)}</p>
                                    </div>
                                    <div className="MainNewsCardLine"></div>
                                    <div className="MainNewsCardText">
                                        <p>{t(`main.news.card4Text`)}</p>
                                    </div>
                                </div>
                            </a>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://youtu.be/VHfbpUolrh8"
                            >
                                <div className="MainNewsCard">
                                    <div className="MainNewsCardImage">
                                        <img src={card5Img} alt="card5 img" />
                                    </div>
                                    <div className="MainNewsCardTitle">
                                        <p>{t(`main.news.cardTitle`)}</p>
                                        <p>{t(`main.news.card5Title`)}</p>
                                    </div>
                                    <div className="MainNewsCardLine"></div>
                                    <div className="MainNewsCardText">
                                        <p>{t(`main.news.card5Text`)}</p>
                                    </div>
                                </div>

                            </a>
                        </div>
                    </div>
                    <div className="MainNewsBtn next">
                        <img
                            src={nextColor}
                            alt="Next Button"
                            onClick={() => sliderMove(+1)}
                        />
                    </div>
                </div>
            </div>
            <div className="MainNewsPreviews">
                <div className="MainNewsReport">
                    <div className="MainNewsReportTitle">
                        <div className="MainNewsReportTitleText">
                            {t(`main.news.titleText1`)}
                        </div>
                        <div
                            className="MainNewsReportTitleBtn"
                            onClick={() => (
                                navigate("/news"), window.scrollTo({ top: 0 })
                            )}
                        >
                            {t(`main.news.fullViewBtn`)}
                        </div>
                    </div>
                    <div className="MainNewsReportLists">
                        {typeof newsList !== "undefined" && newsList.length !== 0
                            ? newsList.map((data, i) => {
                                  return (
                                      <div
                                          className="MainNewsReportList"
                                          key={i}
                                      >
                                          <div className="MainNewsReportListTexts">
                                              <div
                                                  className="MainNewsReportListCategory"
                                                  style={
                                                      data.category === 1
                                                          ? {
                                                                border: "1px solid #e04971",
                                                            }
                                                          : data.category === 2
                                                          ? {
                                                                border: "1px solid #713dc5",
                                                            }
                                                          : {
                                                                border: "1px solid #686868",
                                                            }
                                                  }
                                              >
                                                  {data.category === 1
                                                      ? t(`news.category1`)
                                                      : data.category === 2
                                                      ? t(`news.category2`)
                                                      : t(`news.category3`)}
                                              </div>
                                              <div className="MainNewsReportListTitle">
                                                  <a
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      href={data.link}
                                                  >
                                                      {data.title}
                                                  </a>
                                              </div>
                                          </div>
                                          <div className="MainNewsReportListDate">
                                              {toString(data.date)}
                                          </div>
                                      </div>
                                  );
                              })
                            : null}
                    </div>
                </div>
            </div>
            <div className="MainNewsTexts">
                <div className="MainNewsText1">
                    <div className="MainNewsText1Top">
                        {t(`main.news.textTop`)}
                    </div>
                    <div className="MainNewsText1Bottom">
                        {t(`main.news.textBottom`)}
                    </div>
                </div>
                <div className="MainNewsTextWrap">
                    <div className="MainNewsText">
                        <span>{t(`main.news.textSpan1_1`)}</span>
                        {t(`main.news.textSpan1_2`)}
                    </div>
                    <div className="MainNewsText">
                        <span>{t(`main.news.textSpan2_1`)}</span>
                        {t(`main.news.textSpan2_2`)}
                    </div>
                    <div className="MainNewsText">
                        <span>{t(`main.news.textSpan3_1`)}</span>
                        {t(`main.news.textSpan3_2`)}
                    </div>
                    <div className="MainNewsText">
                        <span>{t(`main.news.textSpan4_1`)}</span>
                        {t(`main.news.textSpan4_2`)}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default News;
