import "./Introduction.css";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import section1Img from "assets/images/introduction/introduction_section1.png";
import maitLogo from "assets/images/logos/MaiT_black.png";

function Introduction() {
    const { t } = useTranslation();

    const [div2022, setDiv2022] = useState(true);
    const [div2023, setDiv2023] = useState(false);
    const [div2024, setDiv2024] = useState(false);
    const [div2025, setDiv2025] = useState(false);
    const [div2026, setDiv2026] = useState(false);
    const [ulNum, setUlNum] = useState(0);
    const yearClick = (num) => {
        setDiv2022(false);
        setDiv2023(false);
        setDiv2024(false);
        setDiv2025(false);
        setDiv2026(false);
        switch (num) {
            case 2022:
                return setDiv2022(true), setUlNum(0);
            case 2023:
                return setDiv2023(true), setUlNum(1);
            case 2024:
                return setDiv2024(true), setUlNum(2);
            case 2025:
                return setDiv2025(true), setUlNum(3);
            case 2026:
                return setDiv2026(true), setUlNum(4);
            default:
                return setDiv2022(true), setUlNum(5);
        }
    };
    return (
        <div className="introductionWrap">
            <div className="introductionHeader">
                <div className="introductionHeader1">
                    {t(`introduction.intro.text1`)}
                </div>
                <div className="introductionHeader2">
                    {t(`introduction.intro.text2`)}
                </div>
            </div>
            <div className="introductionSection1">
                <div className="introductionText">
                    <div className="introductionText1">
                        {t(`introduction.section1.text1`)}
                    </div>
                    <div className="introductionText2">
                        {t(`introduction.section1.text2`)}
                    </div>
                    <div className="introductionText3">
                        {t(`introduction.section1.text3`)}
                    </div>
                </div>
                <div className="introductionImage">
                    <img src={section1Img} alt="introduction first img" />
                </div>
            </div>
            <div className="introductionSection2">
                <div className="introductionSectionTitle">
                    {t(`introduction.section2.title`)}
                </div>
                <div className="introductionSection2Card">
                    <div className="introductionSection2LogoWrap">
                        <div className="introductionSection2Logo">
                            <img src={maitLogo} alt="MaiT Logo" />
                        </div>
                    </div>
                    <div className="introductionSection2Cards">
                        <div className="introductionSection2CardsLine">
                            <div className="introductionSection2CardItem">
                                <div className="introductionSection2CardTitle">
                                    {t(`introduction.section2.card1Title`)}
                                </div>
                                <div className="introductionSection2CardContents">
                                    <div className="introductionSection2CardContentsLine">
                                        <div className="introductionSection2CardContentsSpot"></div>
                                        <div className="introductionSection2CardContentsText">
                                            {t(
                                                `introduction.section2.card1Text1`
                                            )}
                                        </div>
                                    </div>
                                    <div className="introductionSection2CardContentsLine">
                                        <div className="introductionSection2CardContentsSpot"></div>
                                        <div className="introductionSection2CardContentsText">
                                            {t(
                                                `introduction.section2.card1Text2`
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="introductionSection2CardItem">
                                <div className="introductionSection2CardTitle">
                                    {t(`introduction.section2.card2Title`)}
                                </div>
                                <div className="introductionSection2CardContents">
                                    <div className="introductionSection2CardContentsLine">
                                        <div className="introductionSection2CardContentsSpot"></div>
                                        <div className="introductionSection2CardContentsText">
                                            {t(
                                                `introduction.section2.card2Text1`
                                            )}
                                        </div>
                                    </div>
                                    <div className="introductionSection2CardContentsLine">
                                        <div className="introductionSection2CardContentsSpot"></div>
                                        <div className="introductionSection2CardContentsText">
                                            {t(
                                                `introduction.section2.card2Text2`
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="introductionSection2CardsLine">
                            <div className="introductionSection2CardItem">
                                <div className="introductionSection2CardTitle">
                                    {t(`introduction.section2.card3Title`)}
                                </div>
                                <div className="introductionSection2CardContents">
                                    <div className="introductionSection2CardContentsLine">
                                        <div className="introductionSection2CardContentsSpot"></div>
                                        <div className="introductionSection2CardContentsText">
                                            {t(
                                                `introduction.section2.card3Text1`
                                            )}
                                        </div>
                                    </div>
                                    <div className="introductionSection2CardContentsLine">
                                        <div className="introductionSection2CardContentsSpot"></div>
                                        <div className="introductionSection2CardContentsText">
                                            {t(
                                                `introduction.section2.card3Text2`
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="introductionSection2CardItem">
                                <div className="introductionSection2CardTitle">
                                    {t(`introduction.section2.card4Title`)}
                                </div>
                                <div className="introductionSection2CardContents">
                                    <div className="introductionSection2CardContentsLine">
                                        <div className="introductionSection2CardContentsSpot"></div>
                                        <div className="introductionSection2CardContentsText">
                                            {t(
                                                `introduction.section2.card4Text1`
                                            )}
                                        </div>
                                    </div>
                                    <div className="introductionSection2CardContentsLine">
                                        <div className="introductionSection2CardContentsSpot"></div>
                                        <div className="introductionSection2CardContentsText">
                                            {t(
                                                `introduction.section2.card4Text2`
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="introductionSection2Table">
                    <table>
                        <tbody>
                            <tr>
                                <td>{t(`introduction.section2.tableTr1_1`)}</td>
                                <td>{t(`introduction.section2.tableTr1_2`)}</td>
                            </tr>
                            <tr>
                                <td>{t(`introduction.section2.tableTr2_1`)}</td>
                                <td>{t(`introduction.section2.tableTr2_2`)}</td>
                            </tr>
                            <tr>
                                <td>{t(`introduction.section2.tableTr3_1`)}</td>
                                <td>{t(`introduction.section2.tableTr3_2`)}</td>
                            </tr>
                            <tr>
                                <td>{t(`introduction.section2.tableTr4_1`)}</td>
                                <td>
                                    <p>
                                        <span>
                                            {t(
                                                `introduction.section2.tableTr4_2_1_1`
                                            )}
                                        </span>
                                        {t(
                                            `introduction.section2.tableTr4_2_1_2`
                                        )}
                                    </p>
                                    <p>
                                        <span>
                                            {t(
                                                `introduction.section2.tableTr4_2_2_1`
                                            )}
                                        </span>
                                        {t(
                                            `introduction.section2.tableTr4_2_2_2`
                                        )}
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="introductionSection4">
                <div className="introductionSection4Title">
                    {t(`introduction.section4.title`)}
                </div>
                <div className="introductionSection4Contents">
                    <div className="introductionSection4ContentsLine"></div>
                    <div className="introductionSection4Year">
                        <div
                            className={
                                div2022
                                    ? "introductionSection4YearDiv addDivClass"
                                    : "introductionSection4YearDiv"
                            }
                            onClick={() => yearClick(2022)}
                        >
                            <div className="introductionSection4YearText">
                                2022
                            </div>
                            <div className="introductionSection4YearCircle">
                                <div className="introductionSection4YearInnerCircle"></div>
                            </div>
                        </div>
                        <div
                            className={
                                div2023
                                    ? "introductionSection4YearDiv addDivClass"
                                    : "introductionSection4YearDiv"
                            }
                            onClick={() => yearClick(2023)}
                        >
                            <div className="introductionSection4YearText">
                                2023
                            </div>
                            <div className="introductionSection4YearCircle">
                                <div className="introductionSection4YearInnerCircle"></div>
                            </div>
                        </div>
                        <div
                            className={
                                div2024
                                    ? "introductionSection4YearDiv addDivClass"
                                    : "introductionSection4YearDiv"
                            }
                            onClick={() => yearClick(2024)}
                        >
                            <div className="introductionSection4YearText">
                                2024
                            </div>
                            <div className="introductionSection4YearCircle">
                                <div className="introductionSection4YearInnerCircle"></div>
                            </div>
                        </div>
                        <div
                            className={
                                div2025
                                    ? "introductionSection4YearDiv addDivClass"
                                    : "introductionSection4YearDiv"
                            }
                            onClick={() => yearClick(2025)}
                        >
                            <div className="introductionSection4YearText">
                                2025
                            </div>
                            <div className="introductionSection4YearCircle">
                                <div className="introductionSection4YearInnerCircle"></div>
                            </div>
                        </div>
                        <div
                            className={
                                div2026
                                    ? "introductionSection4YearDiv addDivClass"
                                    : "introductionSection4YearDiv"
                            }
                            onClick={() => yearClick(2026)}
                        >
                            <div className="introductionSection4YearText">
                                2026
                            </div>
                            <div className="introductionSection4YearCircle">
                                <div className="introductionSection4YearInnerCircle"></div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="introductionSection4Text"
                        style={{ marginTop: 4 + ulNum * 92 + "px" }}
                    >
                        {div2022 ? (
                            <ul>
                                <li>{t(`introduction.section4.2022.text1`)}</li>
                                <li>{t(`introduction.section4.2022.text2`)}</li>
                                <li>{t(`introduction.section4.2022.text3`)}</li>
                                <li>{t(`introduction.section4.2022.text4`)}</li>
                                <li>{t(`introduction.section4.2022.text5`)}</li>
                                <li>{t(`introduction.section4.2022.text6`)}</li>
                                <li>{t(`introduction.section4.2022.text7`)}</li>
                                <li>{t(`introduction.section4.2022.text8`)}</li>
                                <li>{t(`introduction.section4.2022.text9`)}</li>
                                <li>
                                    {t(`introduction.section4.2022.text10`)}
                                </li>
                                <li>
                                    {t(`introduction.section4.2022.text11`)}
                                </li>
                            </ul>
                        ) : (
                            ""
                        )}
                        {div2023 ? (
                            <ul>
                                <li>{t(`introduction.section4.2023.text1`)}</li>
                            </ul>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Introduction;
