import "./Solution.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { MainSolutionCardClick } from "store/mainSolutionCardClickSlice";

import circleArrow from "assets/images/icons/arrow-circle-left.png";
import ddhImage from "assets/images/main/solution_ddh_image.png";
import faImage from "assets/images/main/solution_fa_image.png";
import teleImage from "assets/images/main/solution_tele_image.png";
import rImage from "assets/images/main/solution_r_image.png";
import ddhLogo from "assets/images/logos/TheADDH_black.png";
import faLogo from "assets/images/logos/TheAF&A_black.png";
import teleLogo from "assets/images/logos/TheATELE&EOS_black.png";
import rLogo from "assets/images/logos/TheRMSK_black.png";

function Solution() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const cardClick = (text) => {
        dispatch(MainSolutionCardClick(text));
        navigate("/solution");
    };

    return (
        <div className="MainSolutionWrap">
            <div className="MainSolutionTitle">
                <div className="MainSolutionTitleText1">
                    {t(`main.solution.titleText1`)}
                </div>
                <div className="MainSolutionTitleText2">
                    {t(`main.solution.titleText2`)}
                </div>
            </div>
            <div className="MainSolutionCards">
                <div>
                    <div
                        className="MainSolutionCard"
                        onClick={() => cardClick("ddh")}
                    >
                        <div className="MainSolutionCardBtn">
                            <img src={circleArrow} alt="circle arrow icon" />
                        </div>
                        <div className="MainSolutionCardImage">
                            <img src={ddhImage} alt="card ddh img" />
                        </div>
                        <div className="MainSolutionCardLogo">
                            <img src={ddhLogo} alt="ddh logo" />
                        </div>
                        <div className="MainSolutionCardText">
                            {t(`main.solution.cardText1`)}
                        </div>
                    </div>
                    <div
                        className="MainSolutionCard"
                        onClick={() => cardClick("fa")}
                    >
                        <div className="MainSolutionCardBtn">
                            <img src={circleArrow} alt="circle arrow icon" />
                        </div>
                        <div className="MainSolutionCardImage">
                            <img src={faImage} alt="card fa img" />
                        </div>
                        <div className="MainSolutionCardLogo">
                            <img src={faLogo} alt="f&A logo" />
                        </div>
                        <div className="MainSolutionCardText">
                            {t(`main.solution.cardText2`)}
                        </div>
                    </div>
                </div>
                <div>
                    <div
                        className="MainSolutionCard"
                        onClick={() => cardClick("tele")}
                    >
                        <div className="MainSolutionCardBtn">
                            <img src={circleArrow} alt="circle arrow icon" />
                        </div>
                        <div className="MainSolutionCardImage">
                            <img src={teleImage} alt="card tele img" />
                        </div>
                        <div className="MainSolutionCardLogo">
                            <img src={teleLogo} alt="tele logo" />
                        </div>
                        <div className="MainSolutionCardText">
                            {t(`main.solution.cardText3`)}
                        </div>
                    </div>
                    <div
                        className="MainSolutionCard"
                        onClick={() => cardClick("r")}
                    >
                        <div className="MainSolutionCardBtn">
                            <img src={circleArrow} alt="circle arrow icon" />
                        </div>
                        <div className="MainSolutionCardImage">
                            <img src={rImage} alt="card r img" />
                        </div>
                        <div className="MainSolutionCardLogo">
                            <img src={rLogo} alt="r logo" />
                        </div>
                        <div className="MainSolutionCardText">
                            {t(`main.solution.cardText4`)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Solution;
