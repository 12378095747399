import "./Info.css";

import { useTranslation } from "react-i18next";

function Info() {
    const { t } = useTranslation();
    return (
        <div className="MainInfoBG">
            <div className="MainInfoWrap">
                <div className="MainInfoText">
                    <div className="MainInfoText1">{t(`main.info.text1`)}</div>
                    <div className="MainInfoText2">{t(`main.info.text2`)}</div>
                    {/* <div className="MainInfoBtn">{t(`main.info.infoBtn`)}</div> */}
                </div>
                <div className="MainInfoCard card1">
                    <div className="MainInfoCardText">
                        {t(`main.info.card1`)}
                    </div>
                </div>
                <div className="MainInfoCard card2">
                    <div className="MainInfoCardText">
                        {t(`main.info.card2`)}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Info;
