import "./Demo.css";
import { useTranslation } from "react-i18next";

function Demo() {
    const { t } = useTranslation();
    return (
        <div className="MainDemoWrap">
            <div className="MainDemoText1">{t(`main.demo.text1`)}</div>
            <div className="MainDemoText2">{t(`main.demo.text2`)}</div>
            {/* <div className="MainDemoBtn">{t(`main.demo.demoBtn`)}</div> */}
        </div>
    );
}

export default Demo;
