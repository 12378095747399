import "./News.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import API from "utils/api";

import NewsPage from "components/news/NewsPage";
import { useSelector } from "react-redux";

function News() {
    const { t } = useTranslation();

    // news count useEffect
    const [listCount, setListCount] = useState(0);
    useEffect(() => {
        API.news_count()
            .then((res) => {
                // console.log(res.data);
                setListCount(res.data["result"]);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    // news list get useEffect
    const newslistPageNum = useSelector((state) => {
        return state.newslistPageNum.value;
    });
    const [newsList, setNewsList] = useState([]);
    useEffect(() => {
        API.news_get(JSON.stringify([newslistPageNum, 10]))
            .then((res) => {
                // console.log(res.data);
                if (res.data["result"] !== "null") {
                    setNewsList(res.data["result"]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [newslistPageNum]);

    const toString = (time) => {
        var getTime = new Date(time);
        const year = getTime.getFullYear();
        const month = getTime.getMonth() + 1;
        const date = getTime.getDate();
        return `${year}-${month >= 10 ? month : "0" + month}-${
            date >= 10 ? date : "0" + date
        }`;
    };

    return (
        <div className="newsWrap">
            <div className="newsHeader">
                <div className="newsHeaderText1">{t(`news.headerText1`)}</div>
                <div className="newsHeaderText2">{t(`news.headerText2`)}</div>
            </div>
            {
                typeof newsList !== "undefined"?
                <div className="newsLists">
                    <div className="newsListHeader">
                        <div className="newsListCategory">
                            {t(`news.listCategory`)}
                        </div>
                        <div className="newsListTitle">{t(`news.listTitle`)}</div>
                        <div className="newsListPlaceOfPublication">
                            {t(`news.listPlaceOfPublication`)}
                        </div>
                        <div className="newsListDateOfPublication">
                            {t(`news.listDateOfPublication`)}
                        </div>
                    </div>
                    {newsList.length !== 0
                        ? newsList.map((data, i) => {
                              return (
                                  <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={data.link}
                                      key={i}
                                  >
                                      <div className={"newsList newsList" + i}>
                                          <div className="newsListCategory">
                                              <div
                                                  className="newsListCategoryText"
                                                  style={
                                                      data.category === 1
                                                          ? {
                                                                border: "2px solid #e04971",
                                                            }
                                                          : data.category === 2
                                                          ? {
                                                                border: "2px solid #713dc5",
                                                            }
                                                          : {
                                                                border: "2px solid #686868",
                                                            }
                                                  }
                                              >
                                                  {data.category === 1
                                                      ? t(`news.category1`)
                                                      : data.category === 2
                                                      ? t(`news.category2`)
                                                      : t(`news.category3`)}
                                              </div>
                                          </div>
                                          <div className="newsListTitle">
                                              {data.title}
                                          </div>
                                          <div className="newsListPlaceOfPublication">
                                              {data.place}
                                          </div>
                                          <div className="newsListDateOfPublication">
                                              {toString(data.date)}
                                          </div>
                                      </div>
                                  </a>
                              );
                          })
                        : null}
                    <NewsPage />
                </div>:null

            }
        </div>
    );
}

export default News;
