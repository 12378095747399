import "./Intro.css";
import video from "assets/videos/main/main_intro_video.mp4";

import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

function Intro() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div className="MainIntroWrap">
            <div className="MainIntroVideo">
                <video muted autoPlay loop>
                    <source src={video} type="video/mp4" />
                </video>
                <div className="MainIntroVideoText">
                    <div className="MainIntroVideoText1">
                        <p>{t(`main.intro.text1`)}</p>
                        <p>{t(`main.intro.text2`)}</p>
                    </div>
                    <div className="MainIntroVideoText2">
                        <p>{t(`main.intro.text3`)}</p>
                    </div>
                </div>
            </div>
            <div className="MainIntroShortcut">
                <div className="MainIntroShortcutText">
                    {t(`main.intro.shortcut`)}
                </div>
                <div className="MainIntroShortcutLine"></div>
                <div
                    className="MainIntroShortcutBtn"
                    onClick={() => (
                        navigate("/solution"), window.scrollTo({ top: 0 })
                    )}
                >
                    {t(`main.intro.shortcutBtn`)}
                </div>
            </div>
        </div>
    );
}

export default Intro;
