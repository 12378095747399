import { memo } from "react";
import { useTranslation } from "react-i18next";

import section1DDHLogo from "assets/images/logos/TheADDH_black.png";
import section1FALogo from "assets/images/logos/TheAF&A_black.png";
import section1TELELogo from "assets/images/logos/TheATELE&EOS_black.png";
import section1RLogo from "assets/images/logos/TheRMSK_black.png";
import section1DdhImg from "assets/images/solution/solution_section1_ddh.png";
import section1FaImg from "assets/images/solution/solution_section1_fa.png";
import section1TeleImg from "assets/images/solution/solution_section1_tele.png";
import section1RImg from "assets/images/solution/solution_section1_r.png";
import SolutionItem from "./SolutionItem";

function SolutionItems() {
    const { t } = useTranslation();

    const section1Items = [
        {
            item: {
                name: "ddh_project",
                itemlogo: <img src={section1DDHLogo} alt="DDH logo" />,
                subtitle: t(`solution.section1Item.item1.subtitle`),
                explanation: {
                    text: [
                        t(`solution.section1Item.item1.explanation.text1`),
                        t(`solution.section1Item.item1.explanation.text2`),
                    ],
                    subText: [
                        t(`solution.section1Item.item1.subText.text1`),
                        t(`solution.section1Item.item1.subText.text2`),
                        t(`solution.section1Item.item1.subText.text3`),
                        t(`solution.section1Item.item1.subText.text4`),
                    ],
                },
                itemImage: <img src={section1DdhImg} alt="DDH Img" />,
                url: "https://www.youtube.com/watch?v=9NtxY5UHqFU",
            },
        },
        {
            item: {
                name: "fa_project",
                itemlogo: <img src={section1FALogo} alt="FA logo" />,
                subtitle: t(`solution.section1Item.item2.subtitle`),
                explanation: {
                    text: [
                        t(`solution.section1Item.item2.explanation.text1`),
                        t(`solution.section1Item.item2.explanation.text2`),
                    ],
                },
                itemImage: <img src={section1FaImg} alt="FA Img" />,
                url: "https://www.youtube.com/watch?v=PEzS7KjiCNo",
            },
        },
        {
            item: {
                name: "tele_project",
                itemlogo: <img src={section1TELELogo} alt="TELE logo" />,
                subtitle: t(`solution.section1Item.item3.subtitle`),
                explanation: {
                    text: [
                        t(`solution.section1Item.item3.explanation.text1`),
                        t(`solution.section1Item.item3.explanation.text2`),
                    ],
                },
                itemImage: <img src={section1TeleImg} alt="TELE Img" />,
                url: "https://youtu.be/VHfbpUolrh8",
            },
        },
        {
            item: {
                name: "r_project",
                itemlogo: <img src={section1RLogo} alt="R logo" />,
                subtitle: t(`solution.section1Item.item4.subtitle`),
                explanation: {
                    text: [
                        t(`solution.section1Item.item4.explanation.text1`),
                        t(`solution.section1Item.item4.explanation.text2`),
                    ],
                },
                itemImage: <img src={section1RImg} alt="R Img" />,
                url: "https://www.youtube.com/watch?v=oM-uG8ArHR0",
            },
        },
    ];

    return (
        <div className="solutionSection1Items">
            {section1Items.map((item, idx) => (
                <SolutionItem key={idx} item={item} />
            ))}
        </div>
    );
}

export default memo(SolutionItems);
