import "./Recruitment.css";
import { useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import section2Img from "assets/images/recruitment/recruitment_section2_img.png";

function Recruitment() {
    const { t } = useTranslation();

    const [windowWidth, setWindowWidth] = useState(0);
    const innerWidthFunc = () => {
        setWindowWidth(window.innerWidth);
    };

    useLayoutEffect(() => {
        window.addEventListener("resize", innerWidthFunc);
        window.onload = innerWidthFunc();
    }, []);

    return (
        <div className="recruitmentWrap">
            <div className="recruitmentHeader">
                <div className="recruitmentHeaderText1">
                    {t(`recruitment.headerText1`)}
                </div>
                <div className="recruitmentHeaderText2">
                    {t(`recruitment.headerText2`)}
                </div>
            </div>
            <div className="recruitmentSection1">
                <div className="recruitmentSection1Text1">
                    {t(`recruitment.section1.text1`)}
                </div>
                <div className="recruitmentSection1Text2">
                    {t(`recruitment.section1.text2`)}
                </div>
                <div className="recruitmentSection1CardWrap">
                    <div className="recruitmentSection1Card">
                        <div className="recruitmentSection1CardText1">
                            Be Orignal
                        </div>
                        <div className="recruitmentSection1CardText2">
                            {t(`recruitment.section1.cardText1`)}
                        </div>
                    </div>
                    <div className="recruitmentSection1Card">
                        <div className="recruitmentSection1CardText1">
                            Motivate Yourself
                        </div>
                        <div className="recruitmentSection1CardText2">
                            {t(`recruitment.section1.cardText2`)}
                        </div>
                    </div>
                    <div className="recruitmentSection1Card">
                        <div className="recruitmentSection1CardText1">
                            Build With Evidence
                        </div>
                        <div className="recruitmentSection1CardText2">
                            {t(`recruitment.section1.cardText3`)}
                        </div>
                    </div>
                </div>
            </div>
            <div className="recruitmentSection2">
                <div className="recruitmentSection2Texts">
                    <div className="recruitmentSection2TextArea1">
                        <div className="recruitmentSection2TextArea1Text">
                            {t(`recruitment.section2.area1text1`)}
                        </div>
                        <div className="recruitmentSection2TextArea1Text">
                            {t(`recruitment.section2.area1text2`)}
                        </div>
                        <div className="recruitmentSection2TextArea1Text">
                            {t(`recruitment.section2.area1text3`)}
                        </div>
                        <div className="recruitmentSection2TextArea1Text">
                            {t(`recruitment.section2.area1text4`)}
                        </div>
                    </div>
                    <div className="recruitmentSection2TextArea2">
                        {t(`recruitment.section2.area2`)}
                    </div>
                </div>
                <div className="recruitmentSection2Img">
                    <img src={section2Img} alt="recruitment Section2 img" />
                </div>
            </div>
            <div className="recruitmentSection3">
                <div className="recruitmentSection3Title">
                    {t(`recruitment.section3.circleTitle`)}
                </div>
                <div
                    className="recruitmentSection3Area1"
                    style={
                        windowWidth > 1377
                            ? {
                                  background:
                                      "linear-gradient(to bottom,#ebebeb 46%, #e3d9f5 46% 54%,#ebebeb 54%)",
                              }
                            : windowWidth > 826
                            ? {
                                  background:
                                      "linear-gradient(to bottom,#ebebeb 23%, #e3d9f5 23% 27%,#ebebeb 27% 73%, #e3d9f5 73% 77%,#ebebeb 77%)",
                              }
                            : windowWidth > 551
                            ? {
                                  background:
                                      "linear-gradient(to bottom, #ebebeb 15.5%, #e3d9f5 15.5% 17.5%, #ebebeb 17.5% 49%, #e3d9f5 49% 51%, #ebebeb 51% 82.5%, #e3d9f5 82.5% 84.5%, #ebebeb 84.5%)",
                              }
                            : {
                                  background:
                                      "linear-gradient(to left,#ebebeb 49%, #e3d9f5 49% 51%,#ebebeb 51%)",
                              }
                    }
                >
                    <div className="recruitmentSection3Area1Circle">
                        {t(`recruitment.section3.circle1`)}
                    </div>
                    <div className="recruitmentSection3Area1Circle">
                        {t(`recruitment.section3.circle2`)}
                    </div>
                    <div className="recruitmentSection3Area1Circle">
                        {t(`recruitment.section3.circle3`)}
                    </div>
                    <div className="recruitmentSection3Area1Circle">
                        {t(`recruitment.section3.circle4`)}
                    </div>
                    <div className="recruitmentSection3Area1Circle">
                        {t(`recruitment.section3.circle5`)}
                    </div>
                </div>
                <div className="recruitmentSection3Area2">
                    <div className="recruitmentSection3Area2Title">
                        {t(`recruitment.section3.textTitle`)}
                    </div>
                    <div className="recruitmentSection3Area2Text">
                        {t(`recruitment.section3.text1`)}
                    </div>
                    <div className="recruitmentSection3Area2Text">
                        {t(`recruitment.section3.text2`)}
                    </div>
                    <div className="recruitmentSection3Area2Text">
                        {t(`recruitment.section3.text3`)}
                    </div>
                    <div className="recruitmentSection3Area2Text">
                        {t(`recruitment.section3.text4`)}
                    </div>
                    <div className="recruitmentSection3Area2Text">
                        {t(`recruitment.section3.text5`)}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Recruitment;
