import "./Solution.css";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import SolutionItems from "components/solution/SolutionItems";

import { MainSolutionCardClick } from "store/mainSolutionCardClickSlice";

import ddh_Logo from "assets/images/logos/TheADDH.png";
import fa_Logo from "assets/images/logos/TheAF&A.png";
import tele_Logo from "assets/images/logos/TheATELE&EOS.png";
import r_Logo from "assets/images/logos/TheRMSK.png";

import silderPrev from "assets/images/icons/solution_slider_prev.png";
import silderPause from "assets/images/icons/solution_slider_pause.png";
import silderPlay from "assets/images/icons/solution_slider_play.png";
import silderNext from "assets/images/icons/solution_slider_next.png";

import Section2_img1 from "assets/images/solution/solution_section2_img1.png";
import Section2_img2 from "assets/images/solution/solution_section2_img2.png";
import Section2_img3 from "assets/images/solution/solution_section2_img3.png";

function Solution() {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // intro에 화면 자동으로 넘기는 효과
    let [sliderCount, setSliderCount] = useState(1);
    let [sliderTF, setSliderTF] = useState(true);

    const interval = useRef();

    const sliderFunc = () => {
        if (sliderTF) {
            if (sliderCount < 4) {
                setSliderCount(sliderCount + 1);
            } else if ((sliderCount = 4)) {
                setSliderCount(1);
            }
        }
    };

    const sliderCountBtn = (pm) => {
        if (pm === "+") {
            if (sliderCount < 4) {
                setSliderCount(sliderCount + 1);
            } else if (sliderCount === 4) {
                setSliderCount(1);
            }
        } else if (pm === "-") {
            if (sliderCount > 1) {
                setSliderCount(sliderCount - 1);
            } else if (sliderCount === 1) {
                setSliderCount(4);
            }
        }
    };

    useEffect(() => {
        interval.current = setInterval(sliderFunc, 3000);
        return () => {
            clearInterval(interval.current);
        };
    });

    // main에서 제품 클릭시 제품 위치로 이동
    const mainSolutionCardText = useSelector((state) => {
        return state.mainSolutionCardClick.value;
    });

    const handleResize = () => {
        let ddh = document.querySelector(".ddh_project");
        let fa = document.querySelector(".fa_project");
        let tele = document.querySelector(".tele_project");
        let r = document.querySelector(".r_project");
        if (mainSolutionCardText !== "") {
            window.scrollTo({ top: 0 });
            if (mainSolutionCardText === "ddh") {
                window.scrollTo({
                    top: ddh.getBoundingClientRect().top - 100,
                });
                dispatch(MainSolutionCardClick(""));
            } else if (mainSolutionCardText === "fa") {
                window.scrollTo({
                    top: fa.getBoundingClientRect().top - 100,
                });
                dispatch(MainSolutionCardClick(""));
            } else if (mainSolutionCardText === "tele") {
                window.scrollTo({
                    top: tele.getBoundingClientRect().top - 100,
                });
                dispatch(MainSolutionCardClick(""));
            } else if (mainSolutionCardText === "r") {
                window.scrollTo({
                    top: r.getBoundingClientRect().top - 100,
                });
                dispatch(MainSolutionCardClick(""));
            }
        }
    };

    const [scrollY, setScrollY] = useState(0);
    const handleScroll = () => {
        setScrollY(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        window.onload = handleResize();
        window.addEventListener("scroll", handleScroll);
    }, []);

    return (
        <div className="solutionWrap">
            <div className="solutionIntro">
                <div className="solutionSliderBG">
                    {/* <img src={slider_bg} alt="slider bg" /> */}
                </div>
                <div
                    className="solutionSliderWrap"
                    style={{
                        transform: `translateX(${(sliderCount - 1) * -25}%)`,
                    }}
                >
                    <div className="solutionSlider">
                        <img src={ddh_Logo} alt="MaiT® The A DDH™" />
                        {t(`solution.slider.ddh`)}
                    </div>
                    <div className="solutionSlider">
                        <img src={fa_Logo} alt="MaiT® The A F&A™" />
                        {t(`solution.slider.fa`)}
                    </div>
                    <div className="solutionSlider">
                        <img src={tele_Logo} alt="MaiT® The A TELE&EOS™" />
                        {t(`solution.slider.tele`)}
                    </div>
                    <div className="solutionSlider">
                        <img src={r_Logo} alt="MaiT® The R MSK™" />
                        {t(`solution.slider.r`)}
                    </div>
                </div>
                <div className="solutionSliderRemoteWrap">
                    <div className="solutionSliderRemote">
                        <div className="solutionSliderCount">
                            <span>0{sliderCount}</span> / 04
                        </div>
                        <div className="solutionSliderBtn">
                            <div
                                className="solutionSliderPrev"
                                onClick={() => sliderCountBtn("-")}
                            >
                                <img
                                    src={silderPrev}
                                    alt="slider prev button"
                                />
                            </div>
                            <div
                                className="solutionSliderPause"
                                onClick={() => setSliderTF(!sliderTF)}
                            >
                                {sliderTF ? (
                                    <img
                                        src={silderPause}
                                        alt="slider pause button"
                                    />
                                ) : (
                                    <img
                                        src={silderPlay}
                                        alt="slider play button"
                                    />
                                )}
                            </div>
                            <div
                                className="solutionSliderNext"
                                onClick={() => sliderCountBtn("+")}
                            >
                                <img
                                    src={silderNext}
                                    alt="slider next button"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="solutionSection1">
                <div className="solutionSection1Title">
                    <div className="solutionSection1TitleText1">
                        {t(`solution.section1Item.titleText1`)}
                    </div>
                    <div className="solutionSection1TitleText2">
                        {t(`solution.section1Item.titleText2`)}
                    </div>
                </div>
                <SolutionItems />
            </div>
            <div className="solutionSection2">
                <div className="solutionSection2Title">
                    <div className="solutionSection2TitleText1">
                        {t(`solution.section2Item.titleText1`)}
                    </div>
                    <div className="solutionSection2TitleText2">
                        {t(`solution.section2Item.titleText2`)}
                    </div>
                </div>
                <div className="solutionSection2Items">
                    <div className="solutionSection2Item">
                        <div className="solutionSection2ItemImg">
                            <img src={Section2_img1} alt="Medical Img" />
                        </div>
                        <div className="solutionSection2ItemText">
                            Medical Image
                        </div>
                    </div>
                    <div className="solutionSection2Item">
                        <div className="solutionSection2ItemImg">
                            <img src={Section2_img2} alt="Prescription Img" />
                        </div>
                        <div className="solutionSection2ItemText">
                            Prescription
                        </div>
                    </div>
                    <div className="solutionSection2Item">
                        <div className="solutionSection2ItemImg">
                            <img src={Section2_img3} alt="Clinical Info Img" />
                        </div>
                        <div className="solutionSection2ItemText">
                            Clinical Info
                        </div>
                    </div>
                </div>
            </div>
            <div className="solutionSection3">
                <div className="solutionSection3Title">
                    <div className="solutionSection3TitleText1">
                        {t(`solution.section3Item.titleText1`)}
                    </div>
                </div>
                <div className="solutionSection3Items">
                    <div className="solutionSection3Item">
                        <div className="solutionSection3ItemCategory">
                            {t(`solution.section3Item.category1`)}
                        </div>
                        <div className="solutionSection3ItemTitle">
                            {t(`solution.section3Item.itemtitle`)}
                        </div>
                        <div className="solutionSection3ItemSub">
                            <div className="solutionSection3ItemSubText">
                                {t(`solution.section3Item.subText1_1`)}
                                {t(`solution.section3Item.subText1_2`)}
                            </div>
                            <div className="solutionSection3ItemSubText">
                                {t(`solution.section3Item.subText2_1`)}
                                {t(`solution.section3Item.subText2_2`)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Solution;
