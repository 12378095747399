import "./TopBtn.css";
import TopBtnImage from "assets/images/icons/top.png";
import { useEffect, useLayoutEffect, useState } from "react";

function TopBtn() {
    const [scrollY, setScrollY] = useState(0);
    const [clientWidth, setClientWidth] = useState(0);
    const [headerHeight, setHeaderHeight] = useState(89);
    useLayoutEffect(() => {
        window.addEventListener("scroll", handleScroll);
        window.addEventListener("load", clientWidthFunc);
        window.addEventListener("resize", clientWidthFunc);
    }, []);

    const clientWidthFunc = () => {
        setClientWidth(window.innerWidth);
    };

    const handleScroll = () => {
        setScrollY(window.scrollY);
    };

    useEffect(() => {
        if (clientWidth < 500) {
            setHeaderHeight(67);
        }
    }, [clientWidth]);

    return (
        <div
            className="topBtn"
            style={
                scrollY > window.innerHeight - headerHeight
                    ? { display: "block" }
                    : { display: "none" }
            }
        >
            <img
                src={TopBtnImage}
                alt="Top Button"
                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            />
        </div>
    );
}

export default TopBtn;
