import { useTranslation } from "react-i18next";

function SolutionItem(props) {
    const { t } = useTranslation();
    const itemName = props.item["item"]["name"];
    const itemLogo = props.item["item"]["itemlogo"];
    const itemImage = props.item["item"]["itemImage"];
    const subtitle = props.item["item"]["subtitle"];
    const explanation = props.item["item"]["explanation"];
    const url = props.item["item"]["url"];

    return (
        <div className={"solutionSection1Item " + itemName}>
            <div className="solutionSection1LeftItem">
                <div className="solutionSection1ItemLogoArea">
                    <div className="solutionSection1ItemLogo">{itemLogo}</div>
                    <div className="solutionSection1Itemsubtitle">
                        <div className="solutionSection1ItemsubtitleText">
                            {subtitle}
                        </div>
                    </div>
                </div>
                <div className="solutionSection1ItemExplanation">
                    {explanation["text"].map((text, idx) => (
                        <div
                            className="solutionSection1ItemExplanationTextWrap"
                            key={idx}
                        >
                            <div className="solutionSection1ItemExplanationCircle"></div>
                            <div className="solutionSection1ItemExplanationText">
                                {text}
                                <div className="solutionSection1ItemExplanationSubTextWrap">
                                    {idx === 0 && explanation["subText"]
                                        ? explanation["subText"].map(
                                              (subText, idx) => (
                                                  <div
                                                      key={idx}
                                                      className="solutionSection1ItemExplanationSubText"
                                                  >
                                                      - {subText}
                                                  </div>
                                              )
                                          )
                                        : ""}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="solutionSection1RightItem">
                <div className="solutionSection1ItemImage">{itemImage}</div>
                <div className="solutionSection1ItemBtn">
                    <a target="_blank" rel="noopener noreferrer" href={url}>
                        {t(`solution.section1Item.imgBtn`)}
                    </a>
                </div>
            </div>
        </div>
    );
}

export default SolutionItem;
