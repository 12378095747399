import "./NewsPage.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import API from "utils/api";

import { NewslistPageNum } from "store/newslistPageNumSlice";

import first_icon from "assets/images/icons/first_page.png";
import prev_icon from "assets/images/icons/prev_page.png";
import next_icon from "assets/images/icons/next_page.png";
import last_icon from "assets/images/icons/last_page.png";
import activated_first_icon from "assets/images/icons/activated_first_page.png";
import activated_prev_icon from "assets/images/icons/activated_prev_page.png";
import activated_next_icon from "assets/images/icons/activated_next_page.png";
import activated_last_icon from "assets/images/icons/activated_last_page.png";

function NewsPage() {
    const dispatch = useDispatch();

    const newslistPageNum = useSelector((state) => {
        return state.newslistPageNum.value;
    });

    // 환자 리스트 count 받아오는 useEffect
    const [pageLength, setPageLength] = useState(0);
    const [pageNumList, setPageNumList] = useState([]);
    useEffect(() => {
        API.news_count()
            .then((res) => {
                // console.log(res.data);
                setPageLength(Math.ceil(res.data["result"] / 10));
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    // map에 쓸 리스트 만드는 useEffect
    useEffect(() => {
        if (pageLength !== 0) {
            let page_list = [];
            for (let i = 0; i < pageLength; i++) {
                page_list.push(i);
            }
            setPageNumList(page_list);
        }
    }, [pageLength]);

    // map에 쓰이는 filter
    const pageNumListFilter = (num) => {
        if (newslistPageNum < 2) {
            return num < 5;
        } else if (pageNumList.length - 1 - newslistPageNum < 2) {
            return num > pageNumList.length - 6;
        } else {
            return num - newslistPageNum < 3 && num - newslistPageNum > -3;
        }
    };
    return (
        <div className="newsPageWrap">
            <div className="firstBtn">
                {newslistPageNum !== 0 ? (
                    <img
                        src={activated_first_icon}
                        alt="first page icon"
                        onClick={() =>
                            dispatch(NewslistPageNum({ text: "zero" }))
                        }
                        style={{ cursor: "pointer" }}
                    />
                ) : (
                    <img src={first_icon} alt="first page icon" />
                )}
            </div>
            <div className="newsPrevBtn">
                {newslistPageNum !== 0 ? (
                    <img
                        src={activated_prev_icon}
                        alt="prev page icon"
                        onClick={() =>
                            dispatch(NewslistPageNum({ text: "minus" }))
                        }
                        style={{ cursor: "pointer" }}
                    />
                ) : (
                    <img src={prev_icon} alt="prev page icon" />
                )}
            </div>
            <div className="newsPagesBtnWrap">
                {pageNumList !== 0
                    ? pageNumList
                          .filter((num) => pageNumListFilter(num))
                          .map((page, idx) => {
                              return (
                                  <div
                                      className="newsPageBtn"
                                      key={idx}
                                      style={
                                          page === newslistPageNum
                                              ? {
                                                    color: "white",
                                                    background: "#713DC5",
                                                }
                                              : {
                                                    color: "#666666",
                                                    background: "white",
                                                }
                                      }
                                      onClick={() =>
                                          dispatch(NewslistPageNum(page))
                                      }
                                  >
                                      {page + 1}
                                  </div>
                              );
                          })
                    : null}
            </div>
            <div className="newsNextBtn">
                {newslistPageNum !== pageNumList.length - 1 ? (
                    <img
                        src={activated_next_icon}
                        alt="next page icon"
                        onClick={() =>
                            dispatch(
                                NewslistPageNum({
                                    text: "plus",
                                    limit: pageNumList.length - 1,
                                })
                            )
                        }
                        style={{ cursor: "pointer" }}
                    />
                ) : (
                    <img src={next_icon} alt="next page icon" />
                )}
            </div>
            <div className="newsLastBtn">
                {newslistPageNum !== pageNumList.length - 1 ? (
                    <img
                        src={activated_last_icon}
                        alt="last page icon"
                        onClick={() =>
                            dispatch(
                                NewslistPageNum({
                                    text: "max",
                                    num: pageNumList.length - 1,
                                })
                            )
                        }
                        style={{ cursor: "pointer" }}
                    />
                ) : (
                    <img src={last_icon} alt="last page icon" />
                )}
            </div>
        </div>
    );
}

export default NewsPage;
