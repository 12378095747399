import "./Main.css";
import Intro from "components/main/intro/Intro";
import Info from "components/main/info/Info";
import Solution from "components/main/solution/Solution";
import News from "components/main/news/News";
import Demo from "components/main/demo/Demo";
import Cooperative from "components/main/cooperative/Cooperative";

function Main() {
    return (
        <div className="mainWrap">
            <Intro />
            <Info />
            <Solution />
            <News />
            <Demo />
            <Cooperative />
        </div>
    );
}
export default Main;
