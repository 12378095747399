import { createSlice } from "@reduxjs/toolkit";

// DDHImagePreCheck.js에서 띄우는 modal의 boolean 값 저장
const loadingSlice = createSlice({
    name: "loadingSlice",
    initialState: { value: false },
    reducers: {
        LoadingTF: (state) => {
            state.value = !state.value;
        },
    },
});

export default loadingSlice;
export const { LoadingTF } = loadingSlice.actions;
