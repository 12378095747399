import { createSlice } from "@reduxjs/toolkit";

const newslistPageNumSlice = createSlice({
    name: "newslistPageNumSlice",
    initialState: { value: 0 },
    reducers: {
        NewslistPageNum: (state, action) => {
            if (action.payload.text === "minus") {
                if (state.value > 0) {
                    state.value = state.value - 1;
                } else {
                    state.value = state.value;
                }
            } else if (action.payload.text === "plus") {
                if (state.value < action.payload.limit) {
                    state.value = state.value + 1;
                } else {
                    state.value = state.value;
                }
            } else if (action.payload.text === "zero") {
                state.value = 0;
            } else if (action.payload.text === "max") {
                state.value = action.payload.num;
            } else {
                state.value = action.payload;
            }
        },
    },
});

export default newslistPageNumSlice;
export const { NewslistPageNum } = newslistPageNumSlice.actions;
