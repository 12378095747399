import "./Cooperative.css";
import { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import cooperative_img1 from "assets/images/main/cooperative_img1.png";
import cooperative_img2 from "assets/images/main/cooperative_img2.png";
import cooperative_img3 from "assets/images/main/cooperative_img3.png";
import cooperative_img4 from "assets/images/main/cooperative_img4.png";
import cooperative_img5 from "assets/images/main/cooperative_img5.png";
import cooperative_img6 from "assets/images/main/cooperative_img6.png";
import cooperative_img7 from "assets/images/main/cooperative_img7.png";
import cooperative_img8 from "assets/images/main/cooperative_img8.png";
import cooperative_img9 from "assets/images/main/cooperative_img9.png";
import cooperative_img10 from "assets/images/main/cooperative_img10.png";

import prev_btn from "assets/images/icons/cooperative_prev.png";
import next_btn from "assets/images/icons/cooperative_next.png";

function useInterval(callback, delay) {
    const savedCallback = useRef();
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

function Cooperative() {
    const { t } = useTranslation();

    const cooperativeImages = [
        [cooperative_img1, "https://www.dsmc.or.kr:49848/"],
        [cooperative_img2, "https://yumc.ac.kr:8443/"],
        [
            cooperative_img3,
            "https://www.pnuh.or.kr/pnuh/main/intro.do?rbsIdx=1",
        ],
        [cooperative_img4, "https://www.cmcvincent.or.kr/page/main"],
        [cooperative_img5, "https://www.snubh.org/index.do"],
        [cooperative_img6, "https://child.snuh.org/main.do"],
        [cooperative_img7, "https://www.paik.ac.kr/seoul/user/main/view.do"],
        [cooperative_img8, "https://www.hshospital.co.kr/ko/index.php"],
        [cooperative_img9, "https://www.amc.seoul.kr/asan/main.do"],
        [cooperative_img10, "https://hyhosp.com/"],
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    // 마우스 올리면 돌아가는 거 멈추는 부분
    const [mouseOver, setMouseOver] = useState(false);
    useInterval(() => {
        if (!mouseOver) setCurrentIndex((currentIndex) => currentIndex + 1);
    }, 2000);

    // 버튼 누르면 좌우로 이동하는 부분
    function handleSlide(index) {
        if (index < 0) {
            // transition 효과를 정지 시킨다.
            if (slideRef.current) {
                slideRef.current.style.transition = "";
            }

            setCurrentIndex(index + 11);

            // transition 효과를 복구한다.
            setTimeout(() => {
                if (slideRef.current) {
                    slideRef.current.style.transition = "all 500ms ease-in-out";
                }
            }, 0);
        } else if (index >= 11) {
            // transition 효과를 정지 시킨다.
            if (slideRef.current) {
                slideRef.current.style.transition = "";
            }

            setCurrentIndex(index - 11);

            // transition 효과를 복구한다.
            setTimeout(() => {
                if (slideRef.current) {
                    slideRef.current.style.transition = "all 500ms ease-in-out";
                }
            }, 0);
        } else {
            setCurrentIndex(index);
        }
    }

    function handleSwipe(direction) {
        handleSlide(currentIndex + direction);
    }

    // 무한 슬라이드를 구현하기 위해 새롭게 배열을 만듦.
    let copiedArr = [...cooperativeImages, ...cooperativeImages];

    const slideRef = useRef();

    if (currentIndex === 11) {
        // transition 효과를 정지
        if (slideRef.current) {
            slideRef.current.style.transition = "";
        }

        setCurrentIndex(0);

        // transition 효과를 복구
        setTimeout(() => {
            if (slideRef.current) {
                slideRef.current.style.transition = "all 500ms ease-in-out";
            }
        }, 0);
    }

    return (
        <div
            className="MainCooperativeWrap"
            onMouseEnter={() => setMouseOver(true)}
            onMouseLeave={() => setMouseOver(false)}
        >
            <div className="MainCooperativeText">
                {t(`main.cooperative.text`)}
            </div>
            <div className="MainCooperativeSliderWrap">
                <div className="MainCooperativeBtn">
                    <img
                        src={prev_btn}
                        alt="prev btn"
                        onClick={() => handleSwipe(-1)}
                    />
                </div>
                <div className="MainCooperativeImgsWrap">
                    <div
                        className="MainCooperativeImgs"
                        ref={slideRef}
                        style={{
                            transform: `translateX(${
                                (-100 / copiedArr.length) * currentIndex
                            }%)`,
                            transition: "all 500ms ease-in-out",
                        }}
                    >
                        {copiedArr.map((img, idx) => {
                            return (
                                <div className="MainCooperativeImg" key={idx}>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={img[1]}
                                    >
                                        <img
                                            src={img[0]}
                                            alt="Cooperative Img"
                                        />
                                    </a>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="MainCooperativeBtn">
                    <img
                        src={next_btn}
                        alt="next btn"
                        onClick={() => handleSwipe(1)}
                    />
                </div>
            </div>
        </div>
    );
}

export default Cooperative;
