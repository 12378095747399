import "./Footer.css";
import { useTranslation } from "react-i18next";

import maitLogo from "assets/images/logos/MaiT_white.png";

function Footer() {
    const { t } = useTranslation();
    return (
        <div className="footerWrap">
            <div className="footer">
                <div className="footerLogo">
                    <img src={maitLogo} alt="Mait Logo" />
                </div>
                <div className="footerText">
                    <div>{t(`footer.text1`)}</div>
                    <div>{t(`footer.text2`)}</div>
                    <div>
                        <div>{t(`footer.text3`)}</div>
                        <div>{t(`footer.text4`)}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
