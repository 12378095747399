import React from "react";
import "./Loading.css";

const Loading = () => {
    return (
        <div className="loading-background">
            <div className="loading-text">Now Loading...</div>
            <div className="loadingio-spinner-spinner-35gbmmlvkk4">
                <div className="ldio-vh27y8oc0rc">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    );
};

export default Loading;
