import "./Customersupport.css";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import API from "utils/api";

import { LoadingTF } from "store/loadingSlice";
import Loading from "components/loading/Loading";

function Customersupport() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const loading = useSelector((state) => {
        return state.loading.value;
    });

    const [customerName, setCustomerName] = useState("");
    const [customerContact, setCustomerContact] = useState("");
    const [customerEmail, setCustomerEmail] = useState("");
    const [customerTitle, setCustomerTitle] = useState("");
    const [customerContent, setCustomerContent] = useState("");
    const [customerConsent, setCustomerConsent] = useState(false);

    const emailSendFunc = () => {
        if (
            customerConsent &&
            customerName !== "" &&
            customerContact !== "" &&
            customerEmail !== "" &&
            customerTitle !== "" &&
            customerContent !== ""
        ) {
            dispatch(LoadingTF());
            const formData = new FormData();
            formData.append("name", customerName);
            formData.append("contact", customerContact);
            formData.append("email", customerEmail);
            formData.append("title", customerTitle);
            formData.append("content", customerContent);
            API.email_send(formData)
                .then((res) => {
                    // console.log(res.data);
                    if (res.data.result === "success") {
                        dispatch(LoadingTF());
                        setCustomerName("");
                        setCustomerContact("");
                        setCustomerEmail("");
                        setCustomerTitle("");
                        setCustomerContent("");
                        setCustomerConsent(false);
                    }
                })
                .catch((err) => {
                    // console.log(err);
                    alert(err);
                    dispatch(LoadingTF());
                });
        }
    };

    return (
        <div className="customerWrap">
            <div className="customerHeader">
                <div className="customerHeaderText1">
                    {t(`customer.header.text1`)}
                </div>
                <div className="customerHeaderText2">
                    {t(`customer.header.text2`)}
                </div>
            </div>
            <div className="customerSection1">
                <div className="customerSection1Header">
                    <div className="customerSection1HeaderText1">
                        <p>{t(`customer.section1.header.text1_1`)}</p>
                        <p>{t(`customer.section1.header.text1_2`)}</p>
                    </div>
                    <div className="customerSection1HeaderText2">
                        {t(`customer.section1.header.text2`)}
                    </div>
                </div>
                <div className="customerSection1Input">
                    <div className="customerInput">
                        <label htmlFor="customerName">
                            {t(`customer.section1.input.1_1`)}
                        </label>
                        <input
                            type="text"
                            id="customerName"
                            placeholder={t(`customer.section1.input.1_2`)}
                            onChange={(e) => setCustomerName(e.target.value)}
                            value={customerName}
                        />
                    </div>
                    <div className="customerInput">
                        <label htmlFor="customerContact">
                            {t(`customer.section1.input.2`)}
                        </label>
                        <input
                            type="text"
                            id="customerContact"
                            placeholder="000-0000-0000"
                            onChange={(e) => setCustomerContact(e.target.value)}
                            value={customerContact}
                        />
                    </div>
                    <div className="customerInput">
                        <label htmlFor="customerEmail">
                            {t(`customer.section1.input.3`)}
                        </label>
                        <input
                            type="text"
                            id="customerEmail"
                            placeholder="mait@mait.healthcare"
                            onChange={(e) => setCustomerEmail(e.target.value)}
                            value={customerEmail}
                        />
                    </div>
                    <div className="customerInput">
                        <label htmlFor="customerTitle">
                            {t(`customer.section1.input.4_1`)}
                        </label>
                        <input
                            type="text"
                            id="customerTitle"
                            placeholder={t(`customer.section1.input.4_2`)}
                            onChange={(e) => setCustomerTitle(e.target.value)}
                            value={customerTitle}
                        />
                    </div>
                    <div className="customerInput">
                        <label htmlFor="customerContent">
                            {t(`customer.section1.input.5_1`)}
                        </label>
                        <textarea
                            type="text"
                            id="customerContent"
                            placeholder={t(`customer.section1.input.5_2`)}
                            onChange={(e) => setCustomerContent(e.target.value)}
                            value={customerContent}
                        />
                    </div>
                </div>
                <div className="customerSection1Consent">
                    <div className="customerSection1ConsentTitle">
                        {t(`customer.section1.consent.title`)}
                    </div>
                    <div className="customerSection1ConsentContent">
                        <div className="customerSection1ConsentContentText">
                            <div className="customerSection1ConsentContentText1">
                                {t(`customer.section1.consent.content1`)}
                            </div>
                            <div className="customerSection1ConsentContentText2">
                                <p>
                                    {t(`customer.section1.consent.content2_1`)}
                                </p>
                                <p>
                                    {t(`customer.section1.consent.content2_2`)}
                                </p>
                            </div>
                            <div className="customerSection1ConsentContentText3">
                                <p>
                                    {t(`customer.section1.consent.content3_1`)}
                                </p>
                                <p>
                                    {t(`customer.section1.consent.content3_2`)}
                                </p>
                            </div>
                            <div className="customerSection1ConsentContentText4">
                                <p>
                                    {t(`customer.section1.consent.content4_1`)}
                                </p>
                                <p>
                                    {t(`customer.section1.consent.content4_2`)}
                                </p>
                            </div>
                            <div className="customerSection1ConsentContentText5">
                                {t(`customer.section1.consent.content5`)}
                            </div>
                        </div>
                        <div className="customerSection1ConsentContentBtn">
                            <input
                                type="checkbox"
                                id="ConsentCheck"
                                className="ConsentCheck"
                                onClick={() =>
                                    setCustomerConsent(!customerConsent)
                                }
                                checked={customerConsent}
                            />
                            <label htmlFor="ConsentCheck">
                                {t(`customer.section1.consent.check`)}
                            </label>
                        </div>
                    </div>
                </div>
                <div className="customerSection1Submission">
                    <div
                        className="customerSection1SubmissionBtn"
                        style={
                            customerConsent &&
                            customerName !== "" &&
                            customerContact !== "" &&
                            customerEmail !== "" &&
                            customerTitle !== "" &&
                            customerContent !== ""
                                ? { background: "#713DC5", cursor: "pointer" }
                                : { background: "#aaa" }
                        }
                        onClick={() => emailSendFunc()}
                    >
                        {t(`customer.section1.submission`)}
                    </div>
                </div>
            </div>
            <div className="customerSection2">
                <div className="customerSection2Title">
                    {t(`customer.section2.title`)}
                </div>
                <div className="customerSection2Card customerSection2Card1">
                    <div className="customerSection2CardTitle">
                        {t(`customer.section2.card1.title`)}
                    </div>
                    <div className="customerSection2CardContent">
                        <p>{t(`customer.section2.card1.content1`)}</p>
                        <p>{t(`customer.section2.card1.content2`)}</p>
                    </div>
                </div>
                <div className="customerSection2Card customerSection2Card2">
                    <div className="customerSection2CardTitle">
                        {t(`customer.section2.card2Title`)}
                    </div>
                    <div className="customerSection2CardContent">
                        <p>mait@mait.healthcare</p>
                    </div>
                </div>
                <div className="customerSection2Card customerSection2Card3">
                    <div className="customerSection2CardTitle">
                        {t(`customer.section2.card3.title`)}
                    </div>
                    <div className="customerSection2CardContent">
                        <p>{t(`customer.section2.card3.content1`)}</p>
                        <p>{t(`customer.section2.card3.content2`)}</p>
                    </div>
                </div>
            </div>
            {loading ? <Loading /> : null}
        </div>
    );
}

export default Customersupport;
